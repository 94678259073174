import React, { Component, useRef } from 'react';
import { Dots, Spinner } from 'react-activity';
import "react-activity/dist/Dots.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// utilities
import Functions from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import moment from 'moment';

import AuthController from '../Utilities/Auth';
import withRouter from '../Utilities/withRouter'
import { Link, Navigate,Redirect  } from 'react-router-dom';
import Axios from 'axios';
import "react-activity/dist/Dots.css";
import Autocomplete from "react-google-autocomplete";
import { appendScript } from '../Utilities/appendScript';



class LocationAdd extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        const { id } = props.params;
        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            isRedirect: false,
            sangathanId: null,
            assignRole: "",
            status: "true",
            name: "",
            place_id: "",
            optionalFieldAllow: null,
            selectedLocation: "",
            addressComponent: null,
            Locations: [],
            _id:id,
            defaultAddress: "",
        };

    }

    async componentDidMount() {

        // await appendScript('/assets/js/pages/crud/file-upload/image-input.js');

      
        if (this.state._id !== '' && this.state._id!=null && this.state._id!=undefined) {
            
        let heading = {
            title: 'Location',
            subTitle: ['Edit Location']
        };
        localStorage.setItem('heading', JSON.stringify(heading));

             await this.getLocation();
        } else {
            
        let heading = {
            title: 'Location',
            subTitle: ['Add Location']
        };
        localStorage.setItem('heading', JSON.stringify(heading));

            this.setState({ loading: false });
        }

    }



    /**
     *
     * @param null
     * @description This function validate inputs
     * @returns boolean
     */
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (this.state.name?.trim() === "") {
            formIsValid = false;
            errors.name = "Please enter event Name";
        }
        this.setState({ errors: errors });

        return formIsValid;
    }
    getLocation = () => {

        let urlPath = "location/GetLocationDetails/"+this.state._id

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responseData) => {

                

                if (responseData.status) {
                    var datares=responseData.data
                    console.log(datares.name);
                    this.setState({     eventName: datares.name,
                        name:datares.name,
                        place_id:datares.place_id,
                        status:datares.status,
                        optionalFieldAllow:datares.optionalFieldAllow,
                        addressComponent:datares.addressComponent,defaultAddress: datares.addressComponent.formatted_address  });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });

    }

    onUserInvite = async (e) => {
        e.preventDefault()

        this.setState({ loading: true })
        const token = await localStorage.getItem('token');
        if (!this.handleValidation()) {
            return false
        }
        // const { state, signOut } = this.context;
        // await this.setState({ isInvitingUser: true })
        const { navigation } = this.props;
        const { name, place_id, status, optionalFieldAllow,addressComponent,_id } = this.state;

        const Body = JSON.stringify({
            name: name,
            place_id:place_id,
            status:status,
            optionalFieldAllow:optionalFieldAllow,
            addressComponent:addressComponent,
            _id:_id
          })
      


        let urlPath = "location/create-location";
        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': token
            },
            body: Body,
          }).then((response) => response.json())
            .then(async (responseData) => {
              if (responseData.status && 'response' in responseData) {
                if(this.state._id==null || this.state._id==''  || this.state._id==undefined  ){
                    alert("Location Successfully Added.")
                }else{
                    alert("Location Successfully Updated.")
                }
                 
                  await this.setState({ loading: false});
                  window.location.href = "/LocationList";
                  // this.redirectWithPath("/LocationList")
              } else if (!responseData.status && "auth" in responseData) {
                  AuthController.Logout();
              } else if (!responseData.status && responseData.error) {
                alert(
                  responseData?.error || "Error")
                  this.setState({ loading: false });
              } else {
                alert(
                  'Something went wrong, Please try again after sometime.'
                )
                this.setState({ loading: false });
              }
            }).catch(error => {
              console.log("err", error);
              alert(
                  'Something went wrong, Please try again after sometime.'
                )
                this.setState({ loading: false });
            }).finally(final => {
              return this.setState({ loading: false })
            });
    }
    redirectWithPath(path) {
        // sangathan-create
        return <Navigate  to={'/LocationList'} />
    }
    getAddressComponentData = (AddressDetails, isPlaceSelected, e = null) => {
        // e.preventDefault()
        if (!AddressDetails) {
            // console.log("IS PlACE ",AddressDetails,isPlaceSelected,e)
            return
        }

        let addressComponentObj = { address_components: AddressDetails.address_components };

        // console.log(JSON.stringify(addressComponentObj));

        this.setState({ addressComponent: addressComponentObj });
    }

    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    render() {

        const { dropdownData } = this.state;

        const fileTypes = ["JPG", "PNG"];
        // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header" >
                                        {this.state._id==''|| this.state._id==null ||this.state._id==undefined ?
                                            <h3 className="card-title" >Add Location</h3>:
                                            <h3 className="card-title" >Edit Location</h3>
                                        }
                                        </div>
                                        <div className="card-body row col-12" >

                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            <input type="text" name="name" className="form-control form-control-solid"
                                                                placeholder="Enter name" onChange={(event) => Functions.onChange(event, this)} value={this.state.name} />
                                                            <span className="form-text text-danger">{this.state.errors?.name}</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Place ID</label>
                                                            <input type="text" name="place_id" className="form-control form-control-solid"
                                                                placeholder="Enter place id" onChange={(event) => Functions.onChange(event, this)} value={this.state.place_id} />
                                                            <span className="form-text text-danger">{this.state.errors?.place_id}</span>
                                                        </div>
                                                    </div>

                                                   

                                                </div>
                                                <div className='row'>
                                                <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Address</label>
                                                            {!this.state.refreshInput ? <Autocomplete  className="form-control form-control-solid py-4 px-8 h-auto"
                                                                placeholder="Enter address"
                                                                apiKey="AIzaSyAeByXtQ4rJOq887gNcEAtAdXysiQgwVOk"
                                                                onPlaceSelected={(place) => this.getAddressComponentData(place, true)}
                                                                defaultValue={this.state.defaultAddress}
                                                                ref={this.googlePlaceInputRef}
                                                                onBlur={(e) => setTimeout(() => this.getAddressComponentData(null, false, e), 500)}
                                                                // inputAutocompleteValue={this.state.defaultAddress}
                                                                options={{
                                                                    // fields: ["address_component", "formatted_address"],
                                                                    types: ["(regions)"]
                                                                }}
                                                            /> :
                                                                <input type="text" disabled name="dummy" className="form-control form-control-solid py-4 px-8 h-auto pac-target-input" placeholder="Enter Address" />
                                                            }
                                                            <span className="form-text text-danger" style={{ padding: 0, }}>{this.state.errors?.address}</span>
                                                        
                                                        </div>
                                                    </div>
                                                   
                                                    <div className='col-md-6'>
                                                        <div className="form-group mt-10">
                                                            <div className="checkbox-inline ">
                                                            {/* <label className="checkbox mr-3">
                                                           
                                                                    <input type="checkbox" name="Status"  value={this.state.status}  onChange={(event) => { (this.state.status=="true"? this.setState({ status : "false" }) :this.setState({ status : "true" })) }}
                                                                    defaultChecked={this.state.status} />
                                                                    <span  ></span>Status</label> */}

                                                                <label className="checkbox">
                                                                    <input type="checkbox" name="OptionalField"  onChange={(event) => { (this.state.optionalFieldAllow==true? this.setState({ optionalFieldAllow : false }) :this.setState({ optionalFieldAllow : true })) }}
                                                                    value={this.state.optionalFieldAllow} 
                                                                    defaultChecked={this.state.optionalFieldAllow}
                                                                    />
                                                                    <span  ></span>Optional Field Allow</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                            </div>
                                        </div>

                                        <div className="card-footer" style={{ justifyContent: 'center', alignItems: 'center' }}>

                                            <button type="button" className="btn btn-primary mr-2" onClick={(event) => this.onUserInvite(event)}>
                                               
                                                {this.state._id==''|| this.state._id==null ||this.state._id==undefined ?
                                                    <label> Add Location</label>:
                                                    <label>Edit Location</label>
                                                }
                                                </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(LocationAdd);
