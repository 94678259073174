import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Dots } from 'react-activity';
import "react-activity/dist/Dots.css";
import QRCode from "react-qr-code";
import Autocomplete from "react-google-autocomplete";

// utilities
import Functions from '../Utilities/Functions';
import Constants from '../Utilities/Constants';

// components
// import MyMap from '../Components/Map';
// import DraggableMarker from '../Components/Map/Components/DraggableMarker';
// import Polygon from '../Components/Map/Components/Polygon';
import AuthController from '../Utilities/Auth';
import withRouter from '../Utilities/withRouter'
import '../../node_modules/react-date-range/dist/styles.css'; // main style file
import '../../node_modules/react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

// const selectionRange = {
//     startDate: new Date(),
//     endDate: new Date(),
//     key: 'selection',
//   }


class EventDashboard extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            isRedirect: false,
            sangathanId: null,
            country_code: '+91',
            ccode: "+91",
            email: "",
            mobileNo: "",
            remark: "",
            assignRole: "",
            firstName: "",
            lastName: "",
            country: "",
            state: "",
            city: "",
            QRCodeValue: "ABCD12345",
            locations: [],
            counts: { totalUsers: 0,
                mainApp: 0,
                gbnApp: 0,
                sangathanApp: 0,
                eventUsers: 0,
                locationUsers: 0,
            },
            selectionRange: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
            eventList: [],
            showDatePicker : false,
        };

    }


    async componentDidMount() {

        // await appendScript('/assets/js/pages/crud/file-upload/image-input.js');
        let heading = {
            title: 'Dashboard',
            subTitle: ['']
        };
        localStorage.setItem('heading', JSON.stringify(heading));

        this.getCounts()

        // Deprecated 
        // this.getLocations()

        // if (this.state.id !== '') {
        //     // await this._getBranch();
        // } else {
        //     this.setState({ loading: false });
        // }

    }

    getLocations = () => {

        let urlPath = "DynamicPage/entry-locations/"

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responseData) => {
                
                // console.log(responseData);

                if (responseData.status) {
                    this.setState({ locations: responseData.data, });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });

    }

    getCounts = (filter = false) => {
        // getReportDashboard
        this.setState({ loading: true });
        let urlPath = "appmanager/dashboard/"

        const startDate = this.state.selectionRange.startDate;
        const endDate = this.state.selectionRange.endDate;

        // if(filter) {
           
        // }


        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                filter: filter,
                startDate: startDate,
                endDate: endDate
            })
        }).then((response) => response.json())
            .then((responseData) => {
                
                // console.log(responseData);

                if (responseData.status) {
                    // {
                    //     "status": true,
                    //     "data": {
                    //         "totalUsers": 2202,
                    //         "mainApp": 1929,
                    //         "gbnApp": 10,
                    //         "sangathanApp": 155,
                    //         "eventUsers": 858
                    //     }
                    // }
                    this.setState({ 
                        counts: { totalUsers: responseData.data.totalUsers,
                            mainApp: responseData.data.mainApp,
                            gbnApp: responseData.data.gbnApp,
                            sangathanApp: responseData.data.sangathanApp,
                            eventUsers: responseData.data.eventUsers,
                            locationUsers: responseData.data.totalLocationsUsers,
                        },
                        locations: responseData.data.locations,
                        eventList: responseData.data.eventList
                    });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                this.setState({ loading: false });
            });

    }




    applyFilter = () => {

        const startDate = new Date(this.state.selectionRange.startDate);
        const endDate = new Date(this.state.selectionRange.endDate);
        let filter = false;
        if (startDate < endDate) {
            filter = true
            // console.log(`${startDate} is earlier than ${endDate}`);
          } else if (startDate > endDate) {
            filter = true
            // console.log(`${startDate} is later than ${endDate}`);
          } else {
            filter = false
            console.log(`${startDate} is the same as ${endDate}`);
          }

       
        // console.log("Date ",this.state.selectionRange,filter,startDate,endDate)
        this.setState({ showDatePicker: false })
        this.getCounts(filter)
    }


 


    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    handleSelect = (ranges) => {
        console.log(ranges);
        this.setState({ selectionRange : ranges.selection })
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
      }

    render() {
     


        const { dropdownData, showDatePicker, selectionRange } = this.state;

        // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    {/* <div className="d-flex flex-column-fluid"> */}
                    <div className="">
                        {this.redirect()}
                        {/* <div className="d-flex flex-column flex-row-fluid wrapper"> */}
                        <div className="float-right">
                                  {showDatePicker ? <>
                                            <button type="button" onClick={() => this.setState({ selectionRange: {
                                                startDate: new Date(),
                                                endDate: new Date(),
                                                key: 'selection',
                                            } })} style={{padding : 18, borderRadius: 0 }} className="btn btn-default">Clear</button>
                                            <button type="button" onClick={this.applyFilter} style={{padding : 18, borderRadius: 0, fontWeight : '600'  }} className="btn btn-primary">Apply</button>
                                        </>
                                    :
                                    <button type="button" onClick={() => this.setState({ showDatePicker: !showDatePicker })} style={{padding : 18, fontWeight : '600' }} className="btn btn-outline">Show Filter</button>}
                                    { showDatePicker && <DateRangePicker
                                            ranges={[selectionRange]}
                                            onChange={this.handleSelect}
                                            className="custom-date-picker"
                                        />}
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* {
                                        this.state.ResErrors.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.ResErrors.error}
                                        </div>
                                    }
                                    {
                                        this.state.successMsg &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMsg}
                                        </div>
                                    } */}

                                   

                                   
                                    <div className="card card-custom gutter-b example example-compact" style={{ marginTop : 50}}>
                                        <div className="card-header" style={{ justifyContent :'center', backgroundImage: " linear-gradient(to right,#D73C34, #B32A61,#90178E)", minHeight: "150px" }}>
                                            {/* style={{backgroundImage:"url(assets/images/bg/bg-2.jpg)", minHeight:"150px"}} */}
                                            {/* <h3 className="card-title"> {this.state.name ? 'Edit' : 'Add'} {this.state.catName}</h3> */}
                                            <h3 className="card-title" style={{ fontSize: "26px", color : 'white' }}>Dashboard</h3>

                                        </div>

                                        <div className="card-body col-12 row" style={{  }}>
                                            
                                            <div className="col-md-12" style={{ display: 'contents' }}>
                                                <div className="col-md-4 col-12">
                                                    <div className="card card-custom  gutter-b example example-compact" style={{   marginTop : 50, boxShadow: '0px 0px 10px 11px rgba(82, 63, 105, 0.05)', paddingLeft : 0, paddingRight : 0 }}>
                                                        <div className="card-header" style={{ backgroundColor : '#bd2f32', justifyContent :'center', minHeight: "50px", paddingLeft : 10, paddingRight : 10 }}>
                                                            {/* style={{backgroundImage:"url(assets/images/bg/bg-2.jpg)", minHeight:"150px"}} */}
                                                            {/* <h3 className="card-title"> {this.state.name ? 'Edit' : 'Add'} {this.state.catName}</h3> */}
                                                            <h3 className="card-title" style={{ fontSize: "20px", color : 'white' }}>Total Users</h3>

                                                        </div>
                                                        <div className="card-body col-12" style={{ textAlign : 'center' }}>
                                                                    <h2 className="" style={{ fontSize: "25px" }}>{this.state.counts.totalUsers}</h2>
                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-md-4 col-12">
                                                    <div className="card card-custom gutter-b example example-compact" style={{  marginTop : 50, boxShadow: '0px 0px 10px 11px rgba(82, 63, 105, 0.05)', paddingLeft : 0, paddingRight : 0 }}>
                                                        <div className="card-header" style={{ backgroundColor : '#bd2f32', justifyContent :'center', minHeight: "50px", paddingLeft : 10, paddingRight : 10 }}>
                                                            {/* style={{backgroundImage:"url(assets/images/bg/bg-2.jpg)", minHeight:"150px"}} */}
                                                            {/* <h3 className="card-title"> {this.state.name ? 'Edit' : 'Add'} {this.state.catName}</h3> */}
                                                            <h3 className="card-title" style={{ fontSize: "20px", color : 'white' }}>Event Users</h3>

                                                        </div>
                                                        <div className="card-body col-12" style={{ textAlign : 'center' }}>
                                                            <h2 className="" style={{ fontSize: "25px" }}>{this.state.counts.eventUsers}</h2>
                                                        </div>

                                                    </div>
                                                </div>

                                                
                                                <div className="col-md-4 col-12">
                                                    <div className="card card-custom gutter-b example example-compact" style={{  marginTop : 50, boxShadow: '0px 0px 10px 11px rgba(82, 63, 105, 0.05)', paddingLeft : 0, paddingRight : 0 }}>
                                                        <div className="card-header" style={{ backgroundColor : '#bd2f32', justifyContent :'center', minHeight: "50px", paddingLeft : 10, paddingRight : 10 }}>
                                                            {/* style={{backgroundImage:"url(assets/images/bg/bg-2.jpg)", minHeight:"150px"}} */}
                                                            {/* <h3 className="card-title"> {this.state.name ? 'Edit' : 'Add'} {this.state.catName}</h3> */}
                                                            <h3 className="card-title" style={{ fontSize: "20px", color : 'white' }}>All Locations Users</h3>

                                                        </div>
                                                        <div className="card-body col-12" style={{ textAlign : 'center' }}>
                                                            <h2 className="" style={{ fontSize: "25px" }}>{this.state.counts.locationUsers}</h2>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div className="col-md-12" style={{ display: 'contents' }}>
                                                <div className="col-md-4 col-12">
                                                    <div className="card card-custom  gutter-b example example-compact" style={{  marginTop : 50, boxShadow: '0px 0px 10px 11px rgba(82, 63, 105, 0.05)', paddingLeft : 0, paddingRight : 0 }}>
                                                        <div className="card-header" style={{ backgroundColor : '#bd2f32', justifyContent :'center', minHeight: "50px", paddingLeft : 10, paddingRight : 10 }}>
                                                            {/* style={{backgroundImage:"url(assets/images/bg/bg-2.jpg)", minHeight:"150px"}} */}
                                                            {/* <h3 className="card-title"> {this.state.name ? 'Edit' : 'Add'} {this.state.catName}</h3> */}
                                                            <h3 className="card-title" style={{ fontSize: "20px", color : 'white' }}>VUF Main App</h3>

                                                        </div>
                                                        <div className="card-body col-12" style={{ textAlign : 'center' }}>
                                                            <h2 className="" style={{ fontSize: "25px" }}>{this.state.counts.mainApp}</h2>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-12">
                                                    <div className="card card-custom  gutter-b example example-compact" style={{  marginTop : 50, boxShadow: '0px 0px 10px 11px rgba(82, 63, 105, 0.05)', paddingLeft : 0, paddingRight : 0 }}>
                                                        <div className="card-header" style={{ backgroundColor : '#bd2f32', justifyContent :'center', minHeight: "50px", paddingLeft : 10, paddingRight : 10 }}>
                                                            {/* style={{backgroundImage:"url(assets/images/bg/bg-2.jpg)", minHeight:"150px"}} */}
                                                            {/* <h3 className="card-title"> {this.state.name ? 'Edit' : 'Add'} {this.state.catName}</h3> */}
                                                            <h3 className="card-title" style={{ fontSize: "20px", color : 'white' }}>VUF GBN App</h3>

                                                        </div>
                                                        <div className="card-body col-12" style={{ textAlign : 'center' }}>
                                                            <h2 className="" style={{ fontSize: "25px" }}>{this.state.counts.gbnApp}</h2>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-12">
                                                    <div className="card card-custom gutter-b example example-compact" style={{  marginTop : 50, boxShadow: '0px 0px 10px 11px rgba(82, 63, 105, 0.05)', paddingLeft : 0, paddingRight : 0 }}>
                                                        <div className="card-header" style={{ backgroundColor : '#bd2f32', justifyContent :'center', minHeight: "50px", paddingLeft : 10, paddingRight : 10 }}>
                                                            {/* style={{backgroundImage:"url(assets/images/bg/bg-2.jpg)", minHeight:"150px"}} */}
                                                            {/* <h3 className="card-title"> {this.state.name ? 'Edit' : 'Add'} {this.state.catName}</h3> */}
                                                            <h3 className="card-title" style={{ fontSize: "20px", color : 'white' }}>VUF Sangathan</h3>

                                                        </div>
                                                        <div className="card-body col-12" style={{ textAlign : 'center' }}>
                                                            <h2 className="" style={{ fontSize: "25px" }}>{this.state.counts.sangathanApp}</h2>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="divider-1" style={{ marginTop : 10, marginBottom : 25, marginLeft : 12.5, marginRight : 12.5  }} />


                                            <div className="col-md-12">
                                            <h3 className="" style={{ fontSize: "26px", color : '#bd2f32', marginBottom : 0, }}>Locations</h3>
                                            </div>
                                            {/* #ededed */}

                                            {   this.state.locations.length > 0 ?

                                                this.state.locations.map((loc,i) => {
                                                    return(
                                                    <div className="col-md-6" style={{ marginTop : 20 }}>
                                                        <div className="col-md-12" style={{ justifyContent :'space-between', alignItems : 'center', backgroundImage: " linear-gradient(to right,#D73C34, #B32A61,#90178E)", minHeight: "50px", borderRadius : 5, padding: '15px 20px', display : 'flex' }}>
                                                            <div style={{ display : 'flex', flexDirection : 'column', }}>
                                                                <span className="" style={{ fontSize: "15px", color : '#ededed', marginBottom : 0 }}>Location Name</span>
                                                                <span className="" style={{ fontSize: "26px", color : 'white', marginBottom : 0, fontWeight : '700' }}>{loc.name}</span>
                                                            </div>
                                                            <div style={{ display : 'flex', flexDirection : 'column', alignItems : 'flex-end' }}>
                                                                <span className="" style={{ fontSize: "15px", color : '#ededed', marginBottom : 0 }}>Users</span>
                                                                <span className="" style={{ fontSize: "26px", color : 'white', marginBottom : 0, fontWeight : '700' }}>{loc.count}</span>
                                                            </div>
                                                            

                                                        </div>
                                                    </div>)
                                                })
                                            : 
                                                <div className="col-md-12" style={{ marginTop : 20 }}>
                                                    <div className="col-md-12" style={{ justifyContent :'center', alignItems : 'center', backgroundImage: " linear-gradient(to right,#D73C34, #B32A61,#90178E)", minHeight: "50px", borderRadius : 5, padding: '15px 20px', display : 'flex' }}>
                                                        <div style={{ display : 'flex', flexDirection : 'column', }}>
                                                            <span className="" style={{ fontSize: "15px", color : '#ededed', marginBottom : 0 }}>No Locations</span>
                                                            
                                                        </div>
                                                        

                                                    </div>
                                                </div>
                                            }


                                            <div className="divider-1" style={{ marginTop : 40, marginBottom : 30, marginLeft : 12.5, marginRight : 12.5  }} />


                                            <div className="col-md-12">
                                            <h3 className="" style={{ fontSize: "26px", color : '#bd2f32', marginBottom : 0, }}>Events</h3>
                                            </div>
                                            {/* #ededed */}

                                            {   this.state.eventList.length > 0 ?

                                                this.state.eventList.map((event,i) => {
                                                    return(
                                                    <div className="col-md-6" style={{ marginTop : 20 }}>
                                                        <div className="col-md-12" style={{ justifyContent :'space-between', alignItems : 'center', backgroundImage: " linear-gradient(to right,#D73C34, #B32A61,#90178E)", minHeight: "50px", borderRadius : 5, padding: '15px 20px', display : 'flex' }}>
                                                            <div style={{ display : 'flex', flexDirection : 'column', }}>
                                                                <span className="" style={{ fontSize: "15px", color : '#ededed', marginBottom : 0 }}>Event Name</span>
                                                                <span className="" style={{ fontSize: "26px", color : 'white', marginBottom : 0, fontWeight : '700' }}>{event.eventName}</span>
                                                            </div>
                                                            <div style={{ display : 'flex', flexDirection : 'column', alignItems : 'flex-end' }}>
                                                                <span className="" style={{ fontSize: "15px", color : '#ededed', marginBottom : 0 }}>Users</span>
                                                                <span className="" style={{ fontSize: "26px", color : 'white', marginBottom : 0, fontWeight : '700' }}>{event.count}</span>
                                                            </div>
                                                            

                                                        </div>
                                                    </div>)
                                                })
                                            : 
                                                <div className="col-md-12" style={{ marginTop : 20 }}>
                                                    <div className="col-md-12" style={{ justifyContent :'center', alignItems : 'center', backgroundImage: " linear-gradient(to right,#D73C34, #B32A61,#90178E)", minHeight: "50px", borderRadius : 5, padding: '15px 20px', display : 'flex' }}>
                                                        <div style={{ display : 'flex', flexDirection : 'column', }}>
                                                            <span className="" style={{ fontSize: "15px", color : '#ededed', marginBottom : 0 }}>No Events</span>
                                                            
                                                        </div>
                                                        

                                                    </div>
                                                </div>
                                            }



                                        </div>

                                        {/* <div className="card-footer" style={{ justifyContent: 'center', alignItems: 'center' }}>

                                            <button type="button" className="btn btn-primary mr-2" onClick={(event) => this.onUserInvite(event)}>Register Now</button>

                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(EventDashboard);
