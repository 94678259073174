import withRouter from '../Utilities/withRouter'
import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Html5QrcodeScanner } from 'html5-qrcode';
import Constants from '../Utilities/Constants';
import { Dots } from 'react-activity';

const EventAttend = (props) => {
    const { id } = props.params;
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [isValidMobileNo, setIsValidMobileNo] = useState(true);
    const [eventList, setEventList] = useState([]);
    // const [event, setEvent] = useState("");
    const [eventId,setEventID]=useState("65c5dfabce585f6d12c398d2")

    useEffect(() => {
        
        // console.log(props)
        // const { id } = props.params;
        onGetEventList()
        setEventID(id);
        const scanner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width: 250,
                height: 250,
            },
            rememberLastUsedCamera: true,
            disableFlip: true,
            fps: 5,
        });

        scanner.render(success, error)
        //   setLoading(false)

        function success(result) {
            scanner.clear();
            setResult(result)
            setLoading(true);
            onScanned("QR", result)
        }
        function error(err) {
            // console.log(err)
        }



    }, []);

    function onGetEventList() {

        let urlPath = "DynamicPage/entry-Event/"
        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {

                if (responseData.status && responseData.response) {

                    setEventList(responseData.response)
                }
            }).catch(error => {
                // // console.log("err", error);

            })
    }
    function scanAgain() {
        window.location.reload();
    }





    function onScanned(mode, scanndata = null) {
        // console.log("CALLING")

        var userId = null;
        var EventId = null;
        if (scanndata != null) {
            userId = scanndata.split("_")[0];
            EventId = scanndata.split("_")[1];
        } else {
            EventId = eventId;
        }

        if (EventId != "") {
            setResult("success")
            setLoading(true)
            const Body = JSON.stringify({
                "mode": mode,
                "mobileNo": mobileNo,
                "userId": userId,
                eventId: EventId,
                'client': Constants.AUTH_APP_NAME,
                'client_secret': Constants.AUTH_SECRET,
            })

            let urlPath = "event/register-user-for-event/";


            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: Body,
            }).then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData);
                    setTimeout(() => {
                        setLoading(false)
                    }, 1000)
                    if (responseData.status) {
                        setShowMessage("Registered Successfully!");



                    } else if (responseData.error && responseData.error && typeof responseData.error != "object") {
                        alert(
                            responseData.error)
                        setLoading(false)
                        setShowMessage(responseData.error)
                    } else if (typeof responseData.error === "object") {
                        alert(
                            'Error!  \n\n ' + responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.'
                        )
                        setShowMessage(responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.')
                        setLoading(false)
                    } else {
                        alert(
                            'Oops! \n\n Something went wrong, Please try again after sometime.'
                        )
                        setShowMessage("Sorry, User not registered. Please try again.")
                        setLoading(false)
                    }

                }).catch(error => {
                    // // console.log("err", error);
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    setShowMessage("Sorry, User not registered. Please try again.")
                    setLoading(false)
                }).finally(final => {

                });
        } else {
            alert("Please select Event.");
            setShowMessage("Please select Event.")
        }
    }

    const handleMobileNoChange = (event) => {
        const newMobileNo = event.target.value;

        // Regular expression for exactly 10 numeric characters
        const numericRegex = /^[0-9]{0,10}$/;

        // Validate the input against the regular expression
        const isValid = numericRegex.test(newMobileNo);

        // Update the isValidMobileNo state based on the validation result
        setIsValidMobileNo(isValid);

        // If the input is valid or empty, and the length is less than or equal to 10, update the mobileNo state
        if ((isValid || newMobileNo === '') && newMobileNo.length <= 10) {
            setMobileNo(newMobileNo);
        }
    };

    const checkMobileNo = () => {
        console.log("M ", mobileNo.length)
        if (mobileNo.length < 10) {
            setIsValidMobileNo(false)
        } else {
            setIsValidMobileNo(true)
        }
    }


    //   loading
    //   ?
    //   <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
    //   :


    return (
        <>
            <div className="d-flex flex-column-fluid">

                <div className="container" style={{ marginTop: 30 }}>
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="card card-custom gutter-b example example-compact">
                                {/* <div className="card-header row" style={{ textAlign : 'center', alignItems : 'center', justifyContent : 'space-between'}}>
                                            <div className='col-md-12' style={{ display : 'flex', justifyContent : 'center'}}>
                                                <img style={{ height : 100, width : 100 }} src={'/assets/UmiyaFoundationWhite.png'} />
                                            </div>
                                           
                                        </div> */}
                                <div className="card-header" style={{ backgroundImage: " linear-gradient(to right,#D73C34, #B32A61,#90178E)", minHeight: "150px" }}>
                                    {/* <h3 className="card-title" style={{ fontSize: "23px" }}>VUF Event Registration</h3> */}
                                    <h3 className="card-title" style={{ fontSize: "23px" }}>Karsevak Abhivadan Samaroh</h3>
                                </div>
                                <div className="card-body row" style={{ paddingTop: 10 }}>




                                    <div className='col-md-12 border-right-desktop-deprecated'>

                                        {/* <div className='col-md-12' style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <label style={{ textAlign: 'center', width: '100%', fontSize: 23, fontWeight: '700', color: '#bc2f30', marginBottom: 10 }}>VUF Event Registration</label>
                                        </div> */}




                                    </div>

                                    {loading ?
                                        <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                                        :
                                        <>
                                            {!result && <div className='col-md-12 ' >
                                                <div className='col-md-12 row'>
                                                    {/* <div className="col-md-4 " style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 0 }}>
                                                        <label>Event:</label>
                                                        <select value={event} className="form-control form-select-solid" onChange={(event) => setEvent(event.target.value)} aria-label="Select Event"  >
                                                            <option value="">Select Event</option>
                                                            {
                                                                eventList.map((eve) => {
                                                                    return (<option value={eve._id}>{eve.eventName}</option>)
                                                                })
                                                            }
                                                        </select>

                                                    </div> */}
                                                    <div className="col-md-8 ">
                                                        <label>Mobile Number / મોબાઇલ નંબર:</label>
                                                        <div className='row'>
                                                            <div className='col-md-2'>
                                                                <input type="text" name="ccode" disabled className="form-control form-control-solid" placeholder="+91" value="+91" />
                                                            </div>
                                                            <div className='col-md-10'>
                                                                <input type="text" name="mobileNo" className="form-control form-control-solid" placeholder="Mobile number" onBlur={checkMobileNo} onChange={handleMobileNoChange} value={mobileNo} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='col-md-12 text-center' style={{ paddingTop: 20, marginBottom: 0 }}>
                                                    <div className='col-md-21 col-sm-12 col-sm-12 margin-top-mob' >
                                                        <div className="form-group text-center">
                                                            <button type="button" disabled={!isValidMobileNo} className="btn btn-primary mr-2" onClick={(event) => onScanned("mobile")}>Register user for event</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>}


                                            <div className='col-md-12 text-center'>

                                                {
                                                    result ?
                                                        <>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <label style={{ textAlign: 'center', width: '100%', fontSize: 18, fontWeight: '700', color: '#363535', marginTop: 20, marginBottom: 20, }}>{showMessage}</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <button type="button" className="btn btn-primary mr-2" onClick={(event) => scanAgain()}>Scan New</button>
                                                                </div>
                                                            </div>

                                                        </>
                                                        :
                                                        <div style={{ flex: 1 }} id="reader"></div>
                                                }


                                            </div>
                                        </>
                                    }



                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};



export default withRouter(EventAttend);
