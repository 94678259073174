import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Dots } from 'react-activity';
import "react-activity/dist/Dots.css";
import QRCode from "react-qr-code";
// utilities
import Functions from '../Utilities/Functions';
import Constants from '../Utilities/Constants';

// components
// import MyMap from '../Components/Map';
// import DraggableMarker from '../Components/Map/Components/DraggableMarker';
// import Polygon from '../Components/Map/Components/Polygon';
import AuthController from '../Utilities/Auth';
import withRouter from '../Utilities/withRouter'
import '../../node_modules/react-date-range/dist/styles.css'; // main style file
import '../../node_modules/react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { alpha, styled } from '@mui/material/styles';
// const selectionRange = {
//     startDate: new Date(),
//     endDate: new Date(),
//     key: 'selection',
//   }


class UserList extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            isRedirect: false,
            sangathanId: null,
            country_code: '+91',
            ccode: "+91",
            email: "",
            mobileNo: "",
            remark: "",
            assignRole: "",
            firstName: "",
            lastName: "",
            UserType: "",

            country: "",
            state: "",
            city: "",
            distict: "",
            village: "",

            countries: [],
            stateList: [],
            districtList: [],
            cityList: [],
            VillageList: [],

            selectedCity: "",
            selectedCountry: "",
            selectedVillage: "",
            selectedDistrict: "",
            selectedState: "",

            cityCode: "",
            districtCode: "",
            stateCode: "",
            countryDataCode: "",
            villageCode: "",

            QRCodeValue: "ABCD12345",
            locations: [],
            counts: {
                totalUsers: 0,
                mainApp: 0,
                gbnApp: 0,
                sangathanApp: 0,
                eventUsers: 0,
                locationUsers: 0,
            },
            selectionRange: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
            eventList: [],
            copy: [],
            userList: [],
            ShowFilters: false,
        };

    }


    async componentDidMount() {

        // await appendScript('/assets/js/pages/crud/file-upload/image-input.js');
        let heading = {
            title: 'User',
            subTitle: ['User List']
        };
        localStorage.setItem('heading', JSON.stringify(heading));
        this.onGetCountryList()
        this.getusers(true,'VUFAPP')
    }
    exportToExcel = (data) => {

        var fileName = "Users"
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${fileName}.xlsx`);
    };
    getLocations = () => {

        let urlPath = "DynamicPage/entry-locations/"

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responseData) => {

                // console.log(responseData);

                if (responseData.status) {
                    this.setState({ locations: responseData.data, });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });

    }

    getusers = (loading,UserType) => {
        // getReportDashboard
        this.setState({ loading: loading });
        let urlPath = "appmanager/appusers/"
        this.state.UserType=UserType;
        if(this.state.UserType==""){
            this.state.UserType="VUFAPP"
        }
        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                UserType: this.state.UserType,
                country:this.state.selectedCountry,
                city: this.state.selectedCity,
                state: this.state.selectedState,
                distict: this.state.selectedDistrict,
                village: this.state.selectedVillage
            })
        }).then((response) => response.json())
            .then((responseData) => {


                if (responseData.status) {

                    this.setState({ userList: responseData.data });
                    this.state.copy=[];
                    this.state.userList.forEach(d => this.state.copy.push({ 'Sr.no.': this.state.userList.indexOf(d) + 1, FirstName: d.firstName, LastName: d.lastName, nickname: d.nickname, MobileNo: d.countryCode + d.mobileNo, profession: d.profession, otherProfession: d.otherProfession, Address: d.street_address, country: d.country, state: d.state, district: d.district, city: d.city, village: d.village, area: d.area, dob: d.dob, age: d.age, remarks: d.remarks }))

                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )

                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                this.setState({ loading: false });
            });

    }

    onGetCountryList = async () => {
        await this.setState({ loading: true })

        let urlPath = "auth/CountryList"

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;

                    let countries = []

                    data.map((c, i) => {
                        countries.push({ label: c.country_name, id: c._id,Code:c.id })
                    })

                    console.log(data)
                    this.setState({ countries: countries, refreshInput: false })
                    
                        this.state.selectedCity = ""
                        this.state.selectedVillage = ""
                        this.state.selectedDistrict = ""
                        this.state.selectedState = ""
                    
                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    onGetStateList = async (data) => {
        console.log(data)
        this.state.countryDataCode = data
        if (data != null && data != undefined && data != '') {

            let urlPath = "auth/StatesList/" + data

            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body: Body,
            }).then((response) => response.json())
                .then(async (responseData) => {
                    // // // console.log("RES ",responseData)
                    if (responseData.status) {
                        var data = responseData.response;

                        let stateList = []

                        data.map((c, i) => {
                            stateList.push({ label: c.state_title, id: c._id,Code:c.state_id })
                        })
                        console.log(stateList)
                        this.setState({ stateList: stateList, refreshInput: false })

                        this.state.selectedState = ""
                        this.state.selectedDistrict = ""
                        this.state.selectedCity = ""
                        this.state.selectedVillage = ""

                        

                    }
                }).catch(error => {
                    // // console.log("err", error);

                }).finally(final => {
                    //return this.setState({ loading: false })
                });
        }
    }
    onGetDistrictList = async (data) => {
        this.state.stateCode = data

        let urlPath = "auth/DistrictsList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let districtList = []

                    data.map((c, i) => {
                        districtList.push({ label: c.district_title, id: c._id,Code:c.districtid })
                    })

                    this.setState({ districtList: districtList, refreshInput: false })

                    
                        this.state.selectedDistrict = ""
                        this.state.selectedCity = ""
                        this.state.selectedVillage = ""
                    


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    onGetCitiesList = async (data) => {
        this.state.districtCode = data
        let urlPath = "auth/CitiesList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let cityList = []
                   
                    data.map((c, i) => {
                        cityList.push({ label: c.name, id: c._id,Code:c.id })
                    })

                    this.setState({ cityList: cityList, refreshInput: false })


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
  


    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    handleSelect = (ranges) => {
        console.log(ranges);
        this.setState({ selectionRange: ranges.selection })
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
    }

    render() {

        const columns = [
            {
                name: 'Sr No',
                width: "10%",
                sortable: true,
                selector: (row, index) => index + 1
            },
            {
                name: 'Name',
                width: "25%",
                sortable: true,
                selector: row => row.firstName + " " + row.lastName
            },
            {
                name: 'Mobile no.',
                width: "15%",
                sortable: true,
                selector: row => row.countryCode + row.mobileNo
            },
            {
                name: 'DOB',
                width: "20%",
                sortable: true,
                selector: row => row.dob != '' ? moment(row.dob).format("DD-MM-YYYY") : ''
            },
            {
                name: 'Age',
                width: "10%",
                sortable: true,
                selector: row => row.age
            },
            {
                name: 'Address',
                width: "30%",
                sortable: true,
                selector: row => (row.street_address || (row.city != null && row.city != '' ? row.city + ", " + row.state + ", " + row.country : '')) || ''
            },
        ];

        const tableData = {
            columns,
            data: this.state.userList,
        };

        // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3 className="card-title" style={{cursor:'pointer'}} onClick={(event) => { (this.state.ShowFilters==true? this.setState({ ShowFilters : false }) :this.setState({ ShowFilters : true })) }} >Filters</h3>
                                    {this.state.ShowFilters == true ?

                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div className="form-group" >
                                                    <label>App Type:</label>
                                                    <select onChange={(e) => { this.setState({ UserType: e.target.value });this.getusers(false,e.target.value) }} className="form-control form-select-solid" >
                                                        <option value="VUFAPP" selected>VUF Main App</option>
                                                        <option value="VUFGBN">VUF GBN App</option>
                                                        <option value="SANGATHAN_BETA">VUF Sangathan</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Country / દેશ:</label>
                                                    <Autocomplete
                                                        disablePortal
                                                        disabled={this.state.countries != undefined && this.state.countries != '' && this.state.countries != null && this.state.countries.length > 0 ? false : true}
                                                        id="combo-box-demo1"
                                                        value={this.state.selectedCountry}
                                                        options={this.state.countries}
                                                        onChange={(e, v, r, d) => {
                                                            // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                            if (r == 'selectOption') {
                                                                this.setState({ selectedCountry: v.label })
                                                                this.onGetStateList(v.Code)
                                                                this.getusers(false,this.state.UserType)
                                                                //this.onSelectCountry(v.label)
                                                            } else if (r == 'clear') {
                                                                this.setState({ selectedState: "" })
                                                                this.setState({ selectedDistrict: "" })
                                                                this.setState({ selectedVillage: "" })
                                                                this.setState({ selectedCity: "" })
                                                                this.setState({ selectedCountry: "" })
                                                                this.getusers(false,this.state.UserType)
                                                            }
                                                        }}

                                                        renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                    />



                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>State / રાજ્ય:</label>
                                                    <Autocomplete
                                                        disablePortal
                                                        disabled={this.state.stateList != undefined && this.state.stateList != '' && this.state.stateList != null && this.state.stateList.length > 0 ? false : true}
                                                        id="combo-box-demo2"
                                                        value={this.state.selectedState}
                                                        options={this.state.stateList}
                                                        onChange={(e, v, r, d) => {
                                                            // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                            if (r == 'selectOption') {
                                                                this.setState({ selectedState: v.label })
                                                                this.onGetDistrictList(v.Code)
                                                                this.getusers(false,this.state.UserType)

                                                            } else if (r == 'clear') {
                                                                this.setState({ selectedDistrict: "" })
                                                                this.setState({ selectedVillage: "" })
                                                                this.setState({ selectedCity: "" })
                                                                this.setState({ selectedState: "" })
                                                                this.getusers(false,this.state.UserType)
                                                            }
                                                        }}

                                                        renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                    />



                                                </div>
                                            </div>

                                        </div>
                                        : <div></div>}
                                    {this.state.ShowFilters == true ?
                                        <div className='row'>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>District / જિલ્લો:</label>
                                                    <Autocomplete
                                                        disablePortal
                                                        disabled={this.state.districtList != undefined && this.state.districtList != '' && this.state.districtList != null && this.state.districtList.length > 0 ? false : true}
                                                        id="combo-box-demo3"
                                                        value={this.state.selectedDistrict}
                                                        options={this.state.districtList}
                                                        onChange={(e, v, r, d) => {
                                                            // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                            if (r == 'selectOption') {
                                                                this.setState({ selectedDistrict: v.label })
                                                                this.onGetCitiesList(v.Code)
                                                                this.getusers(false,this.state.UserType)
                                                                //this.onSelectCountry(v.label)
                                                            } else if (r == 'clear') {
                                                                this.setState({ selectedVillage: "" })
                                                                this.setState({ selectedCity: "" })
                                                                this.setState({ selectedDistrict: "" })
                                                                this.getusers(false,this.state.UserType)
                                                            }
                                                        }}

                                                        renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                    />



                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    
                                                    <label>City / શહેર:</label>
                                                    <Autocomplete
                                                        disablePortal
                                                        disabled={this.state.cityList != undefined && this.state.cityList != '' && this.state.cityList != null && this.state.cityList.length > 0 ? false : true}
                                                        id="combo-box-demo4"
                                                        value={this.state.selectedCity}
                                                        options={this.state.cityList}
                                                        //value={this.state.selectedCity}
                                                        onChange={(e, v, r, d) => {
                                                            // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                            if (r == 'selectOption') {
                                                                this.setState({ selectedCity: v.label })
                                                                this.getusers(false,this.state.UserType)
                                                                //this.onSelectCountry(v.label)
                                                            } else if (r == 'clear') {
                                                                this.setState({ selectedVillage: "" })
                                                                this.setState({ selectedCity: "" })
                                                                this.getusers(false,this.state.UserType)
                                                            }
                                                        }}

                                                        renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                    />



                                                </div>
                                            </div>
                                         
                                        </div>
                                        : <div></div>}

                                    <div className="card card-custom gutter-b example example-compact">

                                        <div className="card-header" >
                                            <div className='col-12 row'>
                                                <div className="col-8 mt-6">
                                                    <h3 className="card-title" >Users</h3>
                                                </div>
                                                <div className="col-4 ">
                                                    <button className=" btn btn-success btn-sm h5 m-3 float-right "
                                                        onClick={(event) => this.exportToExcel(this.state.copy)} >Export to Excel</button>
                                                    {/* <Link to={'/EventList'} className="btn btn-danger btn-sm h5 m-3  mr-2 float-right" >
                                                        <span className="menu-text" style={{ color: 'white' }}>Back to List</span>
                                                    </Link> */}

                                                </div>
                                            </div>


                                        </div>
                                        <div className="card-body row col-12" >

                                            <div className='col-md-12'>
                                                {this.state.userList.length > 0 ?


                                                    <DataTableExtensions
                                                        {...tableData}
                                                        export={false}
                                                        print={false}
                                                    >
                                                        <DataTable
                                                            noHeader
                                                            defaultSortField="id"
                                                            defaultSortAsc={false}
                                                            pagination
                                                            highlightOnHover
                                                            paginationPerPage={30}
                                                        />
                                                    </DataTableExtensions>
                                                    :
                                                    <div className="col-md-12" style={{ marginTop: 20 }}>
                                                        <div className="col-md-12" style={{ justifyContent: 'center', alignItems: 'center', backgroundImage: " linear-gradient(to right,#D73C34, #B32A61,#90178E)", minHeight: "50px", borderRadius: 5, padding: '15px 20px', display: 'flex' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                                                <span className="" style={{ fontSize: "15px", color: '#ededed', marginBottom: 0 }}>No Users</span>

                                                            </div>


                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>

                                        <div className="card-footer" style={{ justifyContent: 'center', alignItems: 'center' }}>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(UserList);
