import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Dots } from 'react-activity';
import "react-activity/dist/Dots.css";
import QRCode from "react-qr-code";
import Autocomplete from "react-google-autocomplete";
import moment from 'moment';
import { Link } from 'react-router-dom';
// utilities
import Constants from '../Utilities/Constants';

import withRouter from '../Utilities/withRouter'
import '../../node_modules/react-date-range/dist/styles.css'; // main style file
import '../../node_modules/react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

class EventUsers extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        const { id } = props.params;
        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            isRedirect: false,
            eventId: id != null ? id : "65c5dfabce585f6d12c398d2",
            Eventdetails:{},
            locations: [],
            copy:[],
            EventStatus:"",
            RegistrationStatus:"",
            counts: {
                totalUsers: 0,
                mainApp: 0,
                gbnApp: 0,
                sangathanApp: 0,
                eventUsers: 0,
                locationUsers: 0,
            },
            selectionRange: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
            userList: [],
            showDatePicker: false,
        };

    }


    async componentDidMount() {

        // await appendScript('/assets/js/pages/crud/file-upload/image-input.js');
        let heading = {
            title: 'Event',
            subTitle: ['Event User']
        };
        localStorage.setItem('heading', JSON.stringify(heading));
        this.getCounts()

        // Deprecated 
        // this.getLocations()

        // if (this.state.id !== '') {
        //     // await this._getBranch();
        // } else {
        //     this.setState({ loading: false });
        // }

    }

    exportToExcel = (data) => {
        
        var fileName = "EventUser"
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${fileName}.xlsx`);
    };

    getLocations = () => {

        let urlPath = "DynamicPage/entry-locations/"

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responseData) => {

                // console.log(responseData);

                if (responseData.status) {
                    this.setState({ locations: responseData.data, });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });

    }


    getCounts = (filter = false) => {
        // getReportDashboard
        this.setState({ loading: true });
        let urlPath = "event/Get-event-users/" + this.state.eventId;

        const startDate = this.state.selectionRange.startDate;
        const endDate = this.state.selectionRange.endDate;

        // if(filter) {

        // }


        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
            // body: JSON.stringify({
            //     filter: filter,
            //     startDate: startDate,
            //     endDate: endDate
            // })
        }).then((response) => response.json())
            .then((responseData) => {

                // console.log(responseData);

                if (responseData.status) {
                    console.log(responseData.data.response)
                    this.setState({

                        userList: responseData.data.response,
                        Eventdetails:responseData.data.Eventdetails.Event
                    });
                    var current=new Date();
                    var EventstartD=new Date(this.state.Eventdetails.eventStartDate);
                    var EventendD=new Date(this.state.Eventdetails.eventEndDate);
                
                     if(EventstartD<current && EventendD<current){
                        this.state.EventStatus="Expired";
                     }
                     else if(EventstartD>=current && EventendD<=current){
                        this.state.EventStatus="Running";
                     }else if(EventstartD>current && EventendD<current){
                        this.state.EventStatus="UpComing";
                     }
                     var RegstartD=new Date(this.state.Eventdetails.registrationStartDate);
                     var RegendD=new Date(this.state.Eventdetails.registrationEndDate);
                     if(RegstartD<current && RegendD<current){
                        this.state.RegistrationStatus="Close";
                     }
                     else if(RegstartD>=current && RegendD<=current){
                        this.state.RegistrationStatus="Open";
                     }else if(RegstartD>current && RegendD<current){
                        this.state.RegistrationStatus="UpComing";
                     }

                    this.state.userList.forEach(d=>this.state.copy.push({'Sr.no.':this.state.userList.indexOf(d)+1,FirstName:d.firstName,LastName:d.lastName,nickname:d.nickname,MobileNo:d.countryCode+d.mobileNo,profession:d.profession,otherProfession:d.otherProfession,Address:d.street_address,country:d.country,state:d.state,district:d.district,city:d.city,village:d.village,area:d.area,dob:d.dob,age:d.age,remarks:d.remarks }))
                    
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )

                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                this.setState({ loading: false });
            });

    }

    applyFilter = () => {

        const startDate = new Date(this.state.selectionRange.startDate);
        const endDate = new Date(this.state.selectionRange.endDate);
        let filter = false;
        if (startDate < endDate) {
            filter = true
            // console.log(`${startDate} is earlier than ${endDate}`);
        } else if (startDate > endDate) {
            filter = true
            // console.log(`${startDate} is later than ${endDate}`);
        } else {
            filter = false
            console.log(`${startDate} is the same as ${endDate}`);
        }


        // console.log("Date ",this.state.selectionRange,filter,startDate,endDate)
        this.setState({ showDatePicker: false })
        this.getCounts(filter)
    }

    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    handleSelect = (ranges) => {
        console.log(ranges);
        this.setState({ selectionRange: ranges.selection })
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
    }

    render() {

        const columns = [
            {
                name: 'Sr No',
                width: "10%",
                sortable: true,
                selector: (row,index) => index+1
            },
            {
                name: 'Name',
                width: "25%",
                sortable: true,
                selector: row => row.firstName + " "+ row.lastName
             },
            {
                name: 'Mobile no.',
                width: "15%",
                sortable: true,
                selector: row => row.countryCode+row.mobileNo
            },
            {
                name: 'DOB',
                width: "20%",
                sortable: true,
                selector: row => row.dob !=''? moment(row.dob).format("DD-MM-YYYY"): ''
            },
            {
                name: 'Age',
                width: "10%",
                sortable: true,
                selector: row => row.age
            },
            {
                name: 'Address',
                width: "30%",
                sortable: true,
                selector: row => (row.street_address || (row.city!=null && row.city!=''? row.city +", "+row.state +", "+ row.country:'') )|| ''
            },
        ];

        const tableData = {
            columns,
            data: this.state.userList,
        };

        const { dropdownData, showDatePicker, selectionRange } = this.state;

        // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header" >
                                           <div className='col-12 row'>
                                            <div className="col-8 mt-6">
                                                   
                                                {/* <h3 className="card-title" >Event Users</h3> */}
                                               <h3>{this.state.Eventdetails.eventName}</h3>

                                               <label>Registration status  
                                               {this.state.RegistrationStatus=="Close" ?  <span style={{ color: 'white' }} className='bg-danger ml-2 p-1 rounded pr-2 pl-2'>Close</span> : ""}
                                               {this.state.RegistrationStatus=="Open" ?  <span style={{ color: 'white' }} className='bg-success ml-2 p-1 rounded pr-2 pl-2'>Open</span> : ""}
                                               {this.state.RegistrationStatus=="UpComing" ?  <span className='bg-warning ml-2 p-1 rounded pr-2 pl-2'>UpComing</span> : ""}
                                                </label>  Registration Date: <label className='h6'>  {moment(this.state.Eventdetails.registrationStartDate).format("DD MMM YYYY")}  to  {moment(this.state.Eventdetails.registrationEndDate).format("DD MMM YYYY")}</label>
                                                <br/>
                                               <label className='ml-2 '>Event status  
                                               {this.state.EventStatus=="Expired" ?  <span style={{ color: 'white' }}className='pr-2 pl-2 ml-2 bg-danger p-1 rounded'>Expired</span> : ""}
                                               {this.state.EventStatus=="Running" ?   <span style={{ color: 'white' }} className='bg-success ml-2 p-1 rounded pr-2 pl-2'>Running</span> : ""}
                                               {this.state.EventStatus=="UpComing" ?  <span className='bg-warning ml-2 p-1 rounded pr-2 pl-2'>UpComing</span> : ""}
                                                </label> Event Date: <label className='h6'> {moment(this.state.Eventdetails.eventStartDate).format("DD MMM YYYY")} to {moment(this.state.Eventdetails.eventEndDate).format("DD MMM YYYY")} </label>
                                            </div>
                                            <div className="col-4 ">
                                                    <button className=" btn btn-success btn-sm h5 m-3 float-right " 
                                                    onClick={(event) => this.exportToExcel(this.state.copy)} >Export to Excel</button>
                                                    <Link to={'/EventList'} className="btn btn-danger btn-sm h5 m-3  mr-2 float-right" >
                                                        <span className="menu-text" style={{ color: 'white' }}>Back to List</span>
                                                    </Link>
                                               
                                            </div>
                                           </div>
                                                   

                                        </div>
                                        <div className="card-body row col-12" >

                                            <div className='col-md-12'>
                                                {this.state.userList.length > 0 ?

                                                    // <table className="table table-head-custom" id="kt_datatable1">
                                                    //     <thead>
                                                    //         <tr>
                                                    //             <th>Sr No</th>
                                                    //             <th>Name</th>
                                                    //             <th>Mobile no.</th>
                                                    //             <th>Email</th>
                                                    //             <th>Gender</th>
                                                    //             <th>Address</th>
                                                    //         </tr>
                                                    //     </thead>
                                                    //     <tbody>
                                                    //         {

                                                    //             this.state.userList.map((item, i) => {
                                                    //                 return (
                                                    //                     <tr>
                                                    //                         <td style={{ width: '10%', textAlign: 'center' }}>{i + 1}</td>
                                                    //                         <td style={{ width: '30%' }}>

                                                    //                             <span className="form-text">{item.eventId}</span>

                                                    //                         </td>
                                                    //                         <td style={{ width: '20%' }}>
                                                    //                         </td>
                                                    //                         <td style={{ width: '20%' }}>
                                                    //                         </td>

                                                    //                         <td style={{ width: '20%' }}>
                                                    //                         </td>
                                                    //                         <td style={{ width: '20%' }}>
                                                    //                         </td>
                                                    //                     </tr>)
                                                    //             })
                                                    //         }
                                                    //     </tbody>
                                                    // </table>
                                                    <DataTableExtensions
                                                    {...tableData}
                                                    export={false}
                                                    print={false}
                                                >
                                                    <DataTable
                                                        noHeader
                                                        defaultSortField="id"
                                                        defaultSortAsc={false}
                                                        pagination
                                                        highlightOnHover
                                                        paginationPerPage={30}
                                                    />
                                                </DataTableExtensions>
                                                    :
                                                    <div className="col-md-12" style={{ marginTop: 20 }}>
                                                        <div className="col-md-12" style={{ justifyContent: 'center', alignItems: 'center', backgroundImage: " linear-gradient(to right,#D73C34, #B32A61,#90178E)", minHeight: "50px", borderRadius: 5, padding: '15px 20px', display: 'flex' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                                                <span className="" style={{ fontSize: "15px", color: '#ededed', marginBottom: 0 }}>No Users</span>

                                                            </div>


                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>

                                        <div className="card-footer" style={{ justifyContent: 'center', alignItems: 'center' }}>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(EventUsers);